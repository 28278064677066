import { render, staticRenderFns } from "./details.vue?vue&type=template&id=0f14b09c&"
import script from "./details.vue?vue&type=script&lang=ts&"
export * from "./details.vue?vue&type=script&lang=ts&"
import style0 from "./details.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBadge,VBtn,VCard,VCheckbox,VChip,VCol,VDivider,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VNavigationDrawer,VRow,VSkeletonLoader,VSlideXTransition})
